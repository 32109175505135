<template>
    <div>
        <table :style='{"line-height": "normal", "font-size":"7pt", "border-collapse": "collapse",}' width="100%">
            <tr>
                <td
                    :style='{...estilos.tablaBorde, ...{"font-weight":"900", "background-color": "#D3D3D3", "text-align":"center"}}'>
                    Escala
                </td>
                <td :style='{...estilos.tablaBorde, ...{"font-weight":"900", "background-color": "#D3D3D3", "text-align":"center"}}'>
                    Valor
                </td>
            </tr>
            <tr
                v-for="(itm, id) in datos?datos.todas:[]"
                :key="id"
            >
                <td :style='{...estilos.tablaBorde , ...{"font-weight":"700"}}'>
                    {{escalasTodas(itm).label}}
                </td>
                <td :style='{...estilos.tablaBorde}'>
                    {{escalasTodas(itm).texto}} ({{escalasTodas(itm).valor}})
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

    methods:{

        escalasTodas(esc){
            let resp = this.calcuaEscala(esc.escala, esc)
            return (resp)
        },

        calcuaEscala(escala, dat){
            let valor = null
            let texto = null
            let label = null
            let color = null

            switch (escala){
                case 'general':
                    label = "Val. general"
                    if (dat){
                        switch(dat.valor){
                            case 1:
                                texto = 'Mejorado'
                                color = 'green'
                                break

                            case 2:
                                texto = 'Delicado'
                                color = 'orange'
                                break

                            case 3:
                                texto = 'Grave'
                                color = 'red'
                                break

                            case 4:
                                texto = 'Muy grave'
                                color = 'black'
                                break
                        }
                        valor = dat.valor
                    }
                break

                case 'reactividad':
                    label = "Reactividad pupilar"
                    if (dat){
                        switch(dat.valor){
                            case 1:
                                texto = 'Isocoria'
                                break

                            case 2:
                                texto = 'Midriasis'
                                break

                            case 3:
                                texto = 'Miosis'
                                break

                            case 4:
                                texto = 'Anisocoria'
                                break
                        }
                        valor = dat.valor

                    }
                    break

                case 'conciencia':
                    label = "Conciencia (Glasgow)"
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let esteValor = con.ocular + con.verbal + con.motor
                        valor = esteValor
                        if (esteValor <= 8){
                            texto = 'Trauma severo'
                            color = 'red'
                        }
                        if (esteValor > 8 && esteValor <= 12){
                            texto = 'Trauma moderado'
                            color = 'orange'
                        }
                        if (esteValor > 12){
                            texto = 'Trauma Leve'
                            color = 'green'
                        }
                    }
                    break

                case 'npass':
                    label = "Dolor (NPASS)"
                    if (dat){
                        //console.log('----')
                        let con = JSON.parse(dat.valorString)
                        //console.log(con)
                        // {"motor":0,"verbal":0,"ocular":0,"sdg":3,"llanto":2,"comportamiento":-1,"facial":0,"muscular":2,"signos":-2,"correccionEdad":0}
                        let esteValor = con.sdg + con.llanto + con.comportamiento + con.facial + con.muscular + con.signos
                        //esteValor = esteValor - 2
                        //console.log(esteValor)
                        valor = esteValor
                        if (esteValor < 0){
                            texto = 'Sedado'
                            color = 'orange'
                        }
                        if (esteValor == 0){
                            texto = 'Normal'
                            color = 'green'
                        }
                        if (esteValor > 0){
                            texto = 'Con dolor'
                            color = 'red'
                        }
                    }
                    break

                case 'ramsay':
                    label = "Sedación (Ramsay)"
                    if(dat){
                        valor = dat.valor

                        if (dat.valor == 1){
                            texto='Despierto, ansioso'
                        }
                        if (dat.valor == 2){
                            texto='Despierto, tranquilo'
                        }
                        if (dat.valor == 3){
                            texto='Dormido a órdenes'
                        }
                        if (dat.valor == 4){
                            texto='Somnoliento'
                        }
                        if (dat.valor == 5){
                            texto='Dormido con respuesta'
                        }
                        if (dat.valor == 6){
                            texto='Profundamente dormido'
                        }
                    }
                    break

                case 'silverman':
                    label = "Silverman"
                    if (dat){
                        let sil = JSON.parse(dat.valorString)
                        let esteValor = sil.torax + sil.ic + sil.xifo + sil.aleteo + sil.quejido
                        valor = esteValor

                        if (esteValor == 0){
                            texto = 'Sin dific.'
                            color = 'green'
                        }

                        if (esteValor > 0 && esteValor <= 3){
                            texto = 'Dific. leve'
                            color = 'blue'
                        }

                        if (esteValor > 3 && esteValor <= 6){
                            texto = 'Dific. moderada'
                            color = 'orange'
                        }
                        if (esteValor > 6){
                            texto = 'Dific. severa'
                            color = 'red'
                        }
                    }
                    break

                case 'caidas':
                    label = "Caidas"
                    if (dat){
                        let valorDat = 0
                        let con = dat.valorString.split(',')
                        con.forEach(c2 => {
                            valorDat = valorDat + parseInt(c2.split('-')[1])
                        });
                        valor = valorDat
                        if (valorDat <= 1){
                            texto = 'Bajo'
                            color = 'green'
                        }
                        if (valorDat > 1 && valorDat <= 3){
                            texto = 'Mediano'
                            color = 'orange'
                        }
                        if (valorDat > 3){
                            texto = 'Alto'
                            color = 'red'
                        }
                    }
                    break

                case 'upp':
                    label = "Úlceras por presión"
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let valDat = con.percepcion + con.humedad + con.actividad+con.movilidad+con.nutricion+con.roce
                        valor = valDat
                        if (valDat < 12){
                            texto = 'Alto'
                            color = 'red'
                        }
                        if (valDat >=12  && valDat <= 14){
                            texto = 'Moderado'
                            color = 'orange'
                        }
                        if (valDat > 14){
                            texto = 'Bajo'
                            color = 'green'
                        }
                    }
                    break

                case 'dolor':
                    label = "Dolor (EVA)"
                    if(dat){
                        valor = dat.valor
                        if (dat.valor == 0){
                            texto='Sin'
                        }
                        if (dat.valor >0 && dat.valor <=2){
                            texto='Poco'
                        }
                        if (dat.valor >2 && dat.valor <=4){
                            texto='Moderado'
                        }
                        if (dat.valor >4 && dat.valor <=6){
                            texto='Fuerte'
                        }
                        if (dat.valor >6 && dat.valor <=8){
                            texto='Muy fuerte'
                        }
                        if (dat.valor >8){
                            texto='Insoportable'
                        }
                    }
                    break

                case 'dolorPipp':
                    label = "Dolor (PIPP)"
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let esteValor = con.edad + con.comportamiento + con.fc + con.sat + con.cejas + con.ojos + con.labio
                        valor = esteValor
                        if (esteValor >= 12){
                            texto = 'Intenso'
                            color = 'red'
                        }
                        if (esteValor > 6 && esteValor < 12){
                            texto = 'Moderado'
                            color = 'orange'
                        }
                        if (esteValor <=6){
                            texto = 'Leve o sin'
                            color = 'green'
                        }
                    }
                    break

                case 'flebitis':
                    label = "Flebitis"
                    if (dat){
                        valor = dat.valor
                        switch(dat.valor){
                            case 0:
                                texto = 'Sin'
                                color = 'green'
                                break

                            case 1:
                                texto = 'Posible'
                                color = 'orange'
                                break

                            case 2:
                                texto = 'Inicio'
                                color = 'red'
                                break

                            case 3:
                                texto = 'Media'
                                color = 'red'
                                break

                            case 4:
                                texto = 'Avanzado'
                                color = 'red'
                                break

                            case 5:
                                texto = 'Tromboflebitis'
                                color = 'red'
                                break
                        }
                    }
                    break
            }
            return {valor:valor, texto:texto, label:label, color:color}
        }
    },

}
</script>

<style>

</style>